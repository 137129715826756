import React from 'react';

export default function UpdateBillingPage() {
    const rawPageData = `<html lang="en">
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <title>MedReps | Update Payment Information</title>
      <link href="https://proxy-assets.churnbuster.io/v3/styles.css" rel="stylesheet">
      <script src="https://proxy-assets.churnbuster.io/v3/scripts.js"></script>
      <script>ChurnBuster.load('454db1ca-3d55-491d-882c-721783a358dd');</script>
    </head>
    <body><noscript><h1>This page requires JavaScript</h1><p>Please enable JavaScript in your browser settings, or use a different web browser like Google Chrome or Safari.</p></noscript></body>
  </html>
  `
    return <div dangerouslySetInnerHTML={{__html: rawPageData}}></div> 
}